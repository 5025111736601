import * as React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import TextPage from "../components/textpage"
import privacy from "../assets/images/privacy.jpg"
import languages from "../utils/enums/languages"

const Privacy = ({ location, pageContext: { translate } }) => (
  <Layout
    secondary
    pageContext={{ translate }}
    location={location}
    SEO={({ data }) => (
      <SEO
        title={data.t?.eraofwe_privacy_policy}
        description={data.t?.privacypolicy_metad}
        keywords={data.t?.privacy_policy}
        languages={[
          ...languages.map(l => {
            return {
              hrefLang: l || 'en',
              href: `${process.env.GATSBY_SITE_URL}${
                l ? "/" + l + "/" : "/"
              }privacy-policy/`,
            }
          }),
          {
            hrefLang: "x-default",
            href: `${process.env.GATSBY_SITE_URL}/privacy-policy/`,
          },
        ]}
      />
    )}
  >
    <>
      {
        <>
          <TextPage pageContext={{ translate }} title={translate.t?.privacy_policy} img={privacy} />
        </>
      }
    </>
  </Layout>
)

export default Privacy
