import * as React from "react"
import Content from "./content"

const TextPage = ({ title, img, pageContext: { translate } }) => {
  return (
    <>
      {
        <div className="text_page">
          <div className="content">
            <div className="text-img">
              <img src={img} alt="Privacy Policy" />
              <h1 className="heading-h1 semi">{title}</h1>
            </div>
            <div className="all-content">
              <Content heading={translate.t?.general}>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_1}
                </p>
              </Content>
              <Content heading={translate.t?.privacy_notice_2}>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_3}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.website}: &nbsp;
                  <a href="https://www.eraofwe.com">SEWN</a>
                </p>
              </Content>

              <Content heading={translate.t?.privacy_notice_4}>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_5}
                </p>
              </Content>

              <Content heading={translate.t?.privacy_notice_6}>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_7}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_8}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_9}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_10}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_11}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_12}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_13}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_14}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_15}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_16}
                </p>
              </Content>
              <Content heading={translate.t?.privacy_notice_17}>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_18}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_19}
                </p>
              </Content>
              <Content heading={translate.t?.privacy_notice_20}>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_21}
                </p>
              </Content>
              <Content heading={translate.t?.privacy_notice_22}>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_23}
                </p>
                <p className="text-16 text-medium bold">
                  {translate.t?.privacy_notice_24}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_25}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_26}
                </p>
                <p className="text-16 text-medium bold">
                  {translate.t?.privacy_notice_27}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_28}
                </p>
                <ul>
                  <li>{translate.t?.privacy_notice_29} </li>
                  <li>{translate.t?.privacy_notice_30}</li>
                  <li>{translate.t?.privacy_notice_31}</li>
                </ul>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_32}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_33}
                </p>
                <p className="text-16 text-medium bold">
                  {translate.t?.privacy_notice_34}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_35}
                </p>
                <ul>
                  <li>{translate.t?.privacy_notice_36}</li>
                  <li>{translate.t?.privacy_notice_37}</li>
                </ul>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_38}
                </p>
                <p className="text-16 text-medium bold">
                  {translate.t?.privacy_notice_39}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_40}
                </p>
                <p className="text-16 text-medium bold">
                  {translate.t?.privacy_notice_41}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_42}
                </p>
                <p className="text-16 text-medium bold">
                  {translate.t?.privacy_notice_43}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_44}
                </p>
                <ul>
                  <li>{translate.t?.privacy_notice_45}</li>
                  <li>{translate.t?.privacy_notice_46}</li>
                  <li>{translate.t?.privacy_notice_47}</li>
                  <li>{translate.t?.privacy_notice_48}</li>
                  <li>{translate.t?.privacy_notice_49}</li>
                  <li>{translate.t?.privacy_notice_50}</li>
                  <li>{translate.t?.privacy_notice_51}</li>
                </ul>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_52}
                </p>
                <p className="text-16 text-medium bold">
                  {translate.t?.privacy_notice_53}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_54}
                </p>
                <ul>
                  <li>{translate.t?.privacy_notice_55}</li>
                  <li>{translate.t?.privacy_notice_56}</li>
                  <li>{translate.t?.privacy_notice_57}</li>
                </ul>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_58}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_59}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_60}
                </p>
              </Content>

              <Content heading={translate.t?.privacy_notice_61}>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_62}
                </p>
                <ul>
                  <li>
                    {translate.t?.privacy_notice_63}{" "}
                    <a
                      href="https://www.networkadvertising.org/managing/opt_out.asp"
                      title="https://www.networkadvertising.org/managing/opt_out.asp"
                    >
                      {translate.t?.privacy_notice_64}
                    </a>{" "}
                    {translate.t?.privacy_notice_65}
                  </li>
                  <li>{translate.t?.privacy_notice_66}</li>
                  <li>{translate.t?.privacy_notice_67}</li>
                  <li>{translate.t?.privacy_notice_68}</li>
                </ul>
              </Content>
              <Content heading={translate.t?.privacy_notice_69}>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_70}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_71}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_72}
                </p>
              </Content>
              <Content heading={translate.t?.privacy_notice_73}>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_74}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_75}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_76}
                </p>
              </Content>
              <Content heading={translate.t?.privacy_notice_77}>
                <p className="text-16 text-medium">
                  {translate.t?.privacy_notice_78}
                </p>
              </Content>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default TextPage
